<script setup>
import { computed } from 'vue';
import { generate } from 'vendor/text-to-image';
import { computedAsync } from '@vueuse/core';
import { colorHash } from '@/utilities/colors';
import { shortName } from '@/utilities/text';

const props = defineProps({
  text: { type: String, default: null },
  name: { type: String, default: null },
  width: { type: Number, required: true },
  height: { type: Number, required: true },
});

const theText = computed(() => {
  return props.text || shortName(props.name || 'Unknown');
});

const imageSrc = computedAsync(
  async () => {
    // access the computed property before passing to async
    const text = theText.value;
    const color = colorHash(props.name || text);

    return await generate(text,
      {
        maxWidth: props.width,
        customHeight: props.height,
        textAlign: 'center',
        verticalAlign: 'center',
        bgColor: color.hex,
        textColor: color.light ? 'black' : 'white',
      });
  },
  null,
);
</script>

<template lang="pug">
img(v-if="imageSrc" :src="imageSrc")
OSkeleton(v-else :width="width + 'px'" :height="height + 'px'")
</template>

<style scoped>

</style>
