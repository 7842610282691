import { computed } from 'vue';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import Compressor from '@uppy/compressor';

/**
 * Creates a computed property for managing uppy uploads
 *
 * @param {string} idName - A unique name to set on this Uppy uploader
 * @param {string[]} allowedTypes - The list of mimetype patterns allowed
 * @param {number|null} maxFileSize - The maximum upload size
 * @param {object} compressorOpts - Any compressor options when compressing images (e.g. maxWidth)
 * @param {(file: File) => UploadManager} startUploader - required callback to create the upload manager with upload params
 * @param {() => void} finishedUpload - Optional callback that is called on successful upload
 * @param {numeric[]|null} thumbnailSize - Set to the width and height of the thumbnail
 * @param {(img: String, file: File) => void} thumbnailReady - Callback when the thumbnail is ready
 * @return {ComputedRef<Uppy<*>>}
 */
export function useUppy({
  idName,
  allowedTypes,
  maxFileSize = null,
  startUploader,
  compressorOpts = {},
  finishedUpload = () => void (0),
  thumbnailSize = null,
  thumbnailReady = (_image) => void (0),
}) {
  return computed(() => {
    const ret = new Uppy({
      id: idName,
      debug: true,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: allowedTypes,
        maxFileSize: maxFileSize,
      },
    });

    ret.use(AwsS3, {
      getUploadParameters: async (file) => {
        const uploader = await startUploader(file);
        ret.setFileState(file.id, { uploadManager: uploader });

        const params = {
          url: uploader.presignedPost.url,
          method: 'POST',
          fields: uploader.presignedPost.fields,
        };
        console.debug('get upload params', file, params);
        return params;
      },
    })
      .on('files-added', (files) => {
        console.debug('Files added', files);
      })
      .on('upload-success', async (file) => {
        await file.uploadManager.finish();
        finishedUpload();
      });

    ret.use(Compressor, {
      retainExif: true,
      maxWidth: 1280,
      convertSize: 1000,
      ...compressorOpts,
    })
      .on('compressor:complete', (files) => {
        console.debug('Compressed', files);
      });

    if (thumbnailSize) {
      ret.use(ThumbnailGenerator, {
        thumbnailWidth: thumbnailSize[0],
        thumbnailHeight: thumbnailSize[1],
        thumbnailType: 'image/png',
      })
        .on('thumbnail:generated', (file, preview) => {
          console.debug('Thumbnailed', file, preview);
          thumbnailReady(preview, file);
        });
    }
    return ret;
  });
}
